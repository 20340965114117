import React from "react";
import OrderBody from "../Order/OrderBody";
import Style from "./BillingList.module.css";
import useLanguageHook from "../../../../hooks/useLanguageHook";
import OrderPriceDetails from "../Order/OrderPriceDetails";
import useFetchData from "../../../../hooks/useFetchData";
import { BeatLoader } from "react-spinners";
import { clientID } from "../../../../Utils/api";

const BillingList = ({ orderContent, settings, tokenData }) => {
  const { getLocalizedString, lang } = useLanguageHook(settings);
  const { isLoading: isSendToRunningLoading, data: sendToRunning, error: sendToRunningError, code: sendToRunningCode, fetchData: sendToRunningFetch } = useFetchData();

  const orderAnotherItem = () => {
    sendToRunningFetch({
      restaurantID: settings.identity,
      apiEndPoint: "/order/order-again",
      method: "POST",
      headers: {
        "x-client-id": clientID(),
        "x-table": tokenData.token
      }
    });
  };

  return (
    <div className={Style.billingWrapper}>
      <div className={Style.title}>
        <p>{getLocalizedString({ key: "My Order", count: orderContent.length })}</p>
      </div>
      <div className={Style.orderItems}>
        <OrderBody tokenData={tokenData} orderContent={orderContent} settings={settings} fromBilling={true} />
      </div>
      <div className={Style.totlaBill}>
        <OrderPriceDetails orderContent={orderContent} settings={settings} fromBilling={true} />
      </div>

      <button onClick={orderAnotherItem} className={Style.orderAnotherItem}>
        {isSendToRunningLoading ? <BeatLoader size={13} margin={3} color="#FFF" /> : <>{getLocalizedString({ key: "Order Another Item" })}</>}
      </button>
    </div>
  );
};

export default BillingList;
