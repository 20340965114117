import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Home from "./Home/Home";
import MenuApp from "./Restaurant/MenuApp/MenuApp";
import RestaurantLandingPage from "./Restaurant/RestaurantLandingPage/RestaurantLandingPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<RestaurantLandingPage />} />
        <Route path="/:tableCODE" element={<MenuApp />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
