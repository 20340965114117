import React, { useEffect, useState } from "react";
import Style from "./Order.module.css";
import useLanguageHook from "../../../../hooks/useLanguageHook";
import OrderItem from "./OrderItem";
import useFetchData from "../../../../hooks/useFetchData";
import OrderPriceDetails from "./OrderPriceDetails";

const OrderBody = ({ tokenData, orderContent, settings, fromBilling = false }) => {
  const { getLocalizedString, lang } = useLanguageHook(settings);

  return (
    <div className={Style.orderContent}>
      {!fromBilling && (
        <div className={Style.title}>
          <p>{getLocalizedString({ key: "My Order", count: orderContent.length })}</p>
        </div>
      )}

      <div className={Style.orderList}>
        {orderContent.map((item, i) => {
          return <OrderItem tokenData={tokenData} order={item} key={i} settings={settings} fromBilling={fromBilling} />;
        })}
      </div>

      {!fromBilling && <OrderPriceDetails orderContent={orderContent} settings={settings} fromBilling={fromBilling} />}
    </div>
  );
};

export default OrderBody;
