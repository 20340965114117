import React, { useEffect, useRef, useState } from "react";
import Style from "./MenuList.module.css";
import Menu from "./Menu";
import { BottomSlideModal, ModalBody, ModalFooter } from "../BottomSlideModal/BottomSlideModal";
import ModalMenuDetails from "./ModalMenuDetails";
import MenuDetailsFooter from "./MenuDetailsFooter";
import Popup from "../Popup/Popup";
import useLanguageHook from "../../../../hooks/useLanguageHook";
import { getName } from "../../../../Utils/utils";
import useFetchData from "../../../../hooks/useFetchData";
import { MenuModal } from "../MenuModal/MenuModal";

const MenuList = ({ settings, selectedCategory, tokenData }) => {
  const [menus, setMenus] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { getLocalizedString, lang } = useLanguageHook(settings);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [orderPlaced, setOrderPlaced] = useState(false);
  const menuRef = useRef(null);

  const handleClose = () => {
    setShowModal(false);
    setTimeout(() => {
      setSelectedMenu(null);
    }, 100);
  };

  useEffect(() => {
    if (selectedMenu) {
      setShowModal(true);
    }
  }, [selectedMenu]);

  useEffect(() => {
    if (selectedCategory && selectedCategory.menus) {
      let item = selectedCategory.menus.sort((a, b) => a.position - b.position);
      setMenus(item);
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (orderPlaced) {
      setShowSuccessPopup(true);
    }
  }, [orderPlaced]);

  useEffect(() => {
    menuRef.current.scrollTop = 1;
  });

  return (
    <>
      <div ref={menuRef} className={`${Style.menuContainer}`}>
        {menus.length > 0 && (
          <div className={Style.menus}>
            {menus.map((menu, i) => {
              return (
                <div
                  onClick={() => {
                    setSelectedMenu(menu);
                  }}
                  key={i}
                  className={Style.menuCard}
                >
                  <Menu settings={settings} menu={menu} />
                </div>
              );
            })}
          </div>
        )}
      </div>

      {selectedMenu && (
        <MenuModal
          tokenData={tokenData}
          setOrderPlaced={setOrderPlaced}
          settings={settings}
          menu={selectedMenu}
          closingOffset={150}
          show={showModal}
          handleClose={handleClose}
        ></MenuModal>
      )}

      <Popup
        type="success"
        title={getLocalizedString({ key: "We received your order." })}
        message={getLocalizedString({
          key: "It takes few minutes for cooking. We will back soon. Thanks for ordering {menu_name}.",
          params: {
            menu_name: getName(settings, selectedMenu)
          }
        })}
        button={getLocalizedString({ key: "Okay" })}
        show={showSuccessPopup}
        handleClose={() => {
          setShowSuccessPopup(false);
        }}
      />
    </>
  );
};

export default MenuList;
