import React, { useEffect, useRef, useState } from "react";
import Style from "./Popup.module.css";
import { BeatLoader } from "react-spinners";

const Popup = ({ type, title, message, button, show, handleClose, actionButton, action }) => {
  const [classList, setClassList] = useState([]);
  const dialogRef = useRef(null);
  const [actionLoading, setActionLoading] = useState(false);
  useEffect(() => {
    if (show) {
      setClassList([Style.dialog, Style.showModal]);
    } else {
      setClassList([]);
    }
    setActionLoading(false);
  }, [show]);

  const hideModal = () => {
    setClassList([Style.dialog, Style.hideModal]);
    setTimeout(() => {
      handleClose();
    }, 600);
  };
  const hideModalWithAction = async () => {
    if (action) {
      setActionLoading(true);
      const response = await action();
      if (response) {
        setClassList([Style.dialog, Style.hideModal]);
        setTimeout(() => {
          handleClose();
        }, 600);
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        hideModal();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClose]);

  return show ? (
    <div className={Style.modal}>
      <div ref={dialogRef} className={classList.join(" ")}>
        <div className={Style.content}>
          <div className={Style.content}>
            <img src={`/${type}.png`} />
            <p className={Style.title}>{title}</p>
            <p className={Style.message}>{message}</p>
          </div>

          <div className={Style.buttonContainer}>
            <button onClick={hideModal} className={`${type == "error" ? Style.button_error : Style.button_success}`}>
              {button}
            </button>

            {actionButton && (
              <button onClick={hideModalWithAction} className={`${type == "error" ? Style.button_error : Style.button_success}`}>
                {actionLoading ? <BeatLoader size={13} margin={0} color="#FF6600" /> : actionButton}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Popup;
