import { useState } from "react";

//firebase imports
import { collection, orderBy, query, limit, startAfter, where, onSnapshot } from "firebase/firestore";
import { db } from "../Utils/firebase";

export const useCollectionSnap = () => {
  const [documents, setDocuments] = useState(null);
  //   const [error, setError] = useState(null)
  const [isPending, setIsPending] = useState(false);
  const [lastDoc, setLastDoc] = useState(null);
  const [isLast, setIsLast] = useState(false);

  const getMultipleDocs = async ({ _collection, _query, orderByField, orderByOption = "desc", _limit, _startAfter }) => {
    setDocuments(null);
    setIsLast(false);
    setIsPending(true);
    let ref = collection(db, _collection);

    //set the query
    if (_query && Object.values(_query)) {
      _query.forEach((queryitem) => {
        if (queryitem[2] === "true" || queryitem[2] === "false") {
          queryitem[2] = queryitem[2] === "true";
        }

        ref = query(ref, where(...queryitem));
      });
    }
    if (orderByField) {
      ref = query(ref, orderBy(orderByField, orderByOption));
    }
    if (_limit) {
      ref = query(ref, limit(_limit));
    }

    if (_startAfter) {
      ref = query(ref, startAfter(_startAfter));
    }

    const unsub = onSnapshot(ref, (snapshot) => {
      let results = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      setIsPending(false);
      setDocuments(results);
      setLastDoc(snapshot.docs[snapshot.docs.length - 1]);

      if (snapshot.empty || (_limit && snapshot.docs.length < _limit)) {
        setIsLast(true);
      }
    });

    return () => unsub();
  };
  return { documents, isPending, lastDoc, isLast, getMultipleDocs };
};
