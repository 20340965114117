import React, { useEffect, useRef, useState } from "react";
import Style from "./LangPopup.module.css";
import { updateLang } from "../../../../Utils/utils";

const LangPopup = ({ settings, setSettings, show, handleClose }) => {
  const [classList, setClassList] = useState([]);
  const dialogRef = useRef(null);
  const [lang, setLang] = useState(document.documentElement.lang || settings.lang);
  useEffect(() => {
    if (show) {
      setClassList([Style.dialog, Style.showModal]);
      document.documentElement.classList.add("modal-open");
    } else {
      setClassList([]);
    }
  }, [show]);

  const hideModal = () => {
    setClassList([Style.dialog, Style.hideModal]);
    setTimeout(() => {
      document.documentElement.classList.remove("modal-open");
      handleClose();
    }, 600);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        hideModal();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClose]);
  const handleLangChange = (selectedLang) => {
    setLang(selectedLang);
    localStorage.setItem(`lang_${settings.identity}`, selectedLang);
    updateLang(settings, setSettings);
    hideModal();
  };

  return show ? (
    <div className={Style.modal}>
      <div ref={dialogRef} className={classList.join(" ")}>
        <div className={Style.content}>
          <div className={Style.item}>
            <input id="lang-en" type="radio" className={Style.radio} name="lang" value={"en"} checked={lang === "en"} onChange={() => handleLangChange("en")} />
            <label htmlFor="lang-en">English</label>
          </div>
          <div className={Style.item}>
            <input id="lang-ja" type="radio" className={Style.radio} name="lang" value={"ja"} checked={lang === "ja"} onChange={() => handleLangChange("ja")} />
            <label htmlFor="lang-ja">Japanese (日本語)</label>
          </div>
          <div className={Style.item}>
            <input id="lang-bn" type="radio" className={Style.radio} name="lang" value={"bn"} checked={lang === "bn"} onChange={() => handleLangChange("bn")} />
            <label htmlFor="lang-bn">Bengali (বাংলা)</label>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default LangPopup;
