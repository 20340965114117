import React, { useEffect, useState } from "react";
import Style from "./Order.module.css";
import useLanguageHook from "../../../../hooks/useLanguageHook";
import { getPrice } from "../../../../Utils/utils";
import Popup from "../Popup/Popup";
import { clientID, fetchData } from "../../../../Utils/api";
import { BeatLoader } from "react-spinners";
import useFetchData from "../../../../hooks/useFetchData";

const OrderFooter = ({ tokenData, tableData, settings, orderContent }) => {
  const { getLocalizedString, lang } = useLanguageHook(settings);
  const [price, setPrice] = useState(0);
  const [disabledOrderButton, setDisabledOrderButton] = useState(false);
  const [pendingItem, setTotalPendingItems] = useState(0);
  const [cookingItem, setTotalCookingItems] = useState(0);
  const [showCallStaffModal, setShowCallStaffModal] = useState(false);
  const [loadingStateRunning, setLoadingStateRunning] = useState(false);

  const { isLoading: isSendToBillLoading, data: sendToBill, error: sendToBillError, code: sendToBillCode, fetchData: sendToBillFetch } = useFetchData();

  const handleBilling = () => {
    if (disabledOrderButton) {
      return;
    }
    let total_pending = 0;
    let total_cooking = 0;

    orderContent.map((item) => {
      if (item.status == "pending") {
        total_pending += 1;
      }
      if (item.status == "cooking") {
        total_cooking += 1;
      }
    });
    setTotalPendingItems(total_pending);
    setTotalCookingItems(total_cooking);
    if (total_cooking > 0) {
      setShowCallStaffModal(true);
    } else {
      sendToBilling();
    }
  };

  useEffect(() => {
    setLoadingStateRunning(false);
  }, []);

  useEffect(() => {
    let priceData = 0;
    let count_cooking_served = 0;
    orderContent.map((order) => {
      if (order.status == "canceled") {
        return;
      }

      if (order.status == "cooking" || order.status == "served") {
        count_cooking_served += 1;
      }

      priceData += (order.price ? order.price : 0) * order.quantity;
    });
    if (settings.add_tax) {
      setPrice(priceData + priceData * (settings.tax_percent / 100));
    } else {
      setPrice(priceData);
    }
    setDisabledOrderButton(count_cooking_served == 0 ? true : false);
  }, [orderContent]);

  const callStaff = async () => {
    const { statusCode, responseBody, error } = await fetchData({
      restaurantID: settings.identity,
      apiEndPoint: "/call-staff",
      method: "POST",
      bodyData: {
        calling_type: "bill"
      },
      headers: {
        "x-client-id": clientID(),
        "x-table": tokenData.token
      }
    });

    return true;
  };

  const sendToBilling = () => {
    setLoadingStateRunning(true);
    sendToBillFetch({
      restaurantID: settings.identity,
      apiEndPoint: "/order/send-to-billing",
      method: "POST",
      bodyData: {
        force: true
      },
      headers: {
        "x-client-id": clientID(),
        "x-table": tokenData.token
      }
    });
  };

  return (
    <>
      <div className={Style.footer}>
        {tableData && tableData.is_staff_calling && tableData.calling_type == "bill" ? (
          <div disabled={disabledOrderButton} className={Style.staffHelpOrder}>
            {getLocalizedString({ key: "Staff is on the way to assist with order processing !" })}
          </div>
        ) : (
          <button onClick={handleBilling} disabled={disabledOrderButton} className={Style.totalBill_btn}>
            {loadingStateRunning ? (
              <BeatLoader size={13} margin={3} color="#FFF" />
            ) : (
              <>
                {getLocalizedString({ key: "Total Bill" })}{" "}
                <span>
                  {settings.currency}
                  {price.toFixed(parseInt(settings.precision))}
                </span>{" "}
                {settings.add_tax && <span className={Style.taxInc}>({getLocalizedString({ key: "Tax inc" })})</span>}
              </>
            )}
          </button>
        )}
      </div>

      <Popup
        type="error"
        title={getLocalizedString({ key: "Items in Cooking" })}
        message={getLocalizedString({
          key: "{count} items are still being cooked. Please wait to be served or call staff to proceed with billing",
          count: cookingItem,
          params: { count: cookingItem }
        })}
        button={getLocalizedString({ key: "Wait" })}
        show={showCallStaffModal}
        handleClose={() => {
          setShowCallStaffModal(false);
        }}
        actionButton={getLocalizedString({ key: "Call Staff" })}
        action={callStaff}
      />
    </>
  );
};

export default OrderFooter;
