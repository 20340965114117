import React, { useState, useEffect } from "react";
import Style from "./Footer.module.css";
import TranslateIcon from "@mui/icons-material/Translate";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import RoomServiceIcon from "@mui/icons-material/RoomService";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LangPopup from "../LangPopup/LangPopup";
import useLanguageHook from "../../../../hooks/useLanguageHook";
import { BottomSlideModal, ModalBody, ModalFooter } from "../BottomSlideModal/BottomSlideModal";
import OrderBody from "../Order/OrderBody";
import OrderFooter from "../Order/OrderFooter";
import useFetchData from "../../../../hooks/useFetchData";
import { ClipLoader } from "react-spinners";
import { clientID } from "../../../../Utils/api";
import Popup from "../Popup/Popup";

const Footer = ({ tableData, tokenData, orderContent, settings, setSettings }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [showLangPopup, setShowLangPopUp] = useState(false);
  const { getLocalizedString, lang } = useLanguageHook(settings);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [callStaffModalTitle, setCallStaffModalTitle] = useState(null);
  const [callStaffModalMessage, setCallStaffModalMessage] = useState(null);
  const [showCallStaffModal, setShowCallStaffModal] = useState(false);
  const { isLoading: isCallStaffLoading, data: callStaffData, error: callStaffError, code: callStaffCode, fetchData: callStaff } = useFetchData();

  useEffect(() => {
    const initialTheme = document.body.getAttribute("theme");
    setIsDarkMode(initialTheme === "dark");
  }, []);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
    document.body.setAttribute("theme", isDarkMode ? "light" : "dark");
  };

  const handleOrderClose = () => {
    setShowOrderModal(false);
  };

  const handleStaffCalling = () => {
    if (tableData && tableData.is_staff_calling && tableData.calling_type) {
      if (tableData.calling_type == "help") {
        showStaffHelpModal();
        return;
      } else if (tableData.calling_type == "bill") {
        showStaffOrderModal();
        return;
      }
    }
    callStaff({
      restaurantID: settings.identity,
      apiEndPoint: "/call-staff",
      method: "POST",
      bodyData: {
        calling_type: "help"
      },
      headers: {
        "x-client-id": clientID(),
        "x-table": tokenData.token
      }
    });
  };

  useEffect(() => {
    if (callStaffData) {
      showStaffHelpModal();
    }
  }, [callStaffData]);

  const showStaffHelpModal = () => {
    setCallStaffModalTitle(getLocalizedString({ key: "Staff is coming.." }));
    setCallStaffModalMessage(getLocalizedString({ key: "It takes a few minutes to come staff and solve your problem." }));
    setShowCallStaffModal(true);
  };
  const showStaffOrderModal = () => {
    setCallStaffModalTitle(getLocalizedString({ key: "Staff is coming.." }));
    setCallStaffModalMessage(getLocalizedString({ key: "It takes a few minutes for the staff to come and complete your billing." }));
    setShowCallStaffModal(true);
  };

  return (
    <>
      <div className={Style.Footer}>
        <div
          className={Style.item}
          onClick={() => {
            setShowLangPopUp(true);
          }}
        >
          <TranslateIcon className={Style.icon} />
        </div>
        <div
          className={Style.item}
          onClick={() => {
            setShowOrderModal(true);
          }}
        >
          <ShoppingBagIcon className={Style.icon} />
          <p>{getLocalizedString({ key: "Order" })}</p>
          {orderContent && Array.isArray(orderContent) && orderContent.length > 0 && <span>{orderContent.length}</span>}
        </div>

        {isCallStaffLoading ? (
          <div className={Style.staffLoading}>
            <ClipLoader size={24} color="#FF6600" />
            <p>{getLocalizedString({ key: "Calling Staff" })}</p>
          </div>
        ) : (
          <div className={Style.item} onClick={handleStaffCalling}>
            <RoomServiceIcon className={`${Style.icon} ${tableData && tableData.is_staff_calling ? Style.glow : ""} `} />
            {tableData && tableData.is_staff_calling ? <p>{getLocalizedString({ key: "Staff Notified" })}</p> : <p>{getLocalizedString({ key: "Call Staff" })}</p>}
          </div>
        )}

        <div className={Style.item} onClick={toggleTheme}>
          {isDarkMode ? <LightModeIcon className={Style.icon} /> : <DarkModeIcon className={Style.icon} />}
        </div>
      </div>

      {showLangPopup && (
        <LangPopup
          show={showLangPopup}
          handleClose={() => {
            setShowLangPopUp(false);
          }}
          settings={settings}
          setSettings={setSettings}
        />
      )}

      {orderContent && Array.isArray(orderContent) && (
        <BottomSlideModal closingOffset={100} show={showOrderModal} handleClose={handleOrderClose}>
          <>
            <ModalBody>
              <OrderBody tokenData={tokenData} orderContent={orderContent} settings={settings} />
            </ModalBody>
            <ModalFooter>
              <OrderFooter tokenData={tokenData} tableData={tableData} settings={settings} orderContent={orderContent} />
            </ModalFooter>
          </>
        </BottomSlideModal>
      )}

      <Popup
        type="staff_calling"
        title={callStaffModalTitle}
        message={callStaffModalMessage}
        button={getLocalizedString({ key: "Okay" })}
        show={showCallStaffModal}
        handleClose={() => {
          setShowCallStaffModal(false);
          setCallStaffModalMessage(null);
          setCallStaffModalTitle(null);
        }}
      />
    </>
  );
};

export default Footer;
