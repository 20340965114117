import React, { useEffect } from "react";
import LazyLoad from "react-lazyload"; // Import LazyLoad from react-lazyload
import Style from "./MenuList.module.css";
import DynamicAspectRatioImage from "../DynamicAspectRatioImage/DynamicAspectRatioImage";
import { getName, getPrice, setEqualHeight } from "../../../../Utils/utils";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";

const Menu = ({ settings, menu }) => {
  const { width } = useWindowDimensions();
  useEffect(() => {
    setEqualHeight("menu-list-menu-name");
  }, [width]);
  return (
    <div className={Style.menu_container}>
      <div className={Style.imgContainer}>
        <DynamicAspectRatioImage src={menu.img} aspectRatio={1 / 1} />
      </div>
      <p className={`${Style.name} menu-list-menu-name`}>{getName(settings, menu)}</p>
      <p className={`${Style.price} ${settings.currency == "円" ? Style.yenPadding : ""}`}>
        {getPrice(settings, menu)}
        <span>{settings.currency}</span>
      </p>
    </div>
  );
};

export default Menu;
