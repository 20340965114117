import React, { useEffect, useRef, useState } from "react";
import Style from "./ModalMenuDetails.module.css";
import { getName } from "../../../../Utils/utils";
import MenuOption from "./MenuOption";
import useLanguageHook from "../../../../hooks/useLanguageHook";

const MenuGroup = ({ group, setSelectedGroupOptions, settings }) => {
  const [selectedOptions, setSelectedOption] = useState([]);
  const errorRef = useRef(null);
  const [error, setError] = useState(null);
  const { getLocalizedString, lang } = useLanguageHook(settings);
  useEffect(() => {
    const orderOptions = selectedOptions.map((item) => ({
      option_id: item,
      option_quantity: 1
    }));

    setSelectedGroupOptions((prevSelectedGroupOptions) => ({
      ...prevSelectedGroupOptions,
      [group.id]: orderOptions
    }));
  }, [selectedOptions]);

  const handleRadio = (id) => {
    setSelectedOption([id]);
  };

  useEffect(() => {
    if (group.type == "radio") {
      if (group.group_options && Array.isArray(group.group_options) && group.group_options.length > 0) {
        if (group.group_options[0] && group.group_options[0].id) {
          setSelectedOption([group.group_options[0].id]);
        }
      }
    }
  }, []);

  const handleCheckbox = (id) => {
    if (!selectedOptions.includes(id)) {
      if (selectedOptions.length >= group.max_select) {
        setError(getLocalizedString({ key: "You can select maximum {count} items.", params: { count: group.max_select }, count: group.max_select }));
        if (errorRef.current) {
          errorRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
        }
        return;
      }
      setSelectedOption([...selectedOptions, id]);
    } else {
      setSelectedOption(selectedOptions.filter((item) => item !== id));
    }
    setError(null);
  };
  return (
    <>
      {group.group_options && Array.isArray(group.group_options) && group.group_options.length > 0 && (
        <div className={Style.group}>
          <p className={Style.sectionTitle}>{getName(settings, group)}</p>
          <p className={Style.groupError} ref={errorRef}>
            {error}
          </p>
          <div className={Style.options}>
            {group.group_options.map((item, i) => {
              return (
                <MenuOption
                  onClick={group.type == "radio" ? handleRadio : handleCheckbox}
                  key={i}
                  group={group}
                  option={item}
                  settings={settings}
                  handleRadio={handleRadio}
                  handleCheckbox={handleCheckbox}
                  selectedOptions={selectedOptions}
                />
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default MenuGroup;
