import { useState, useEffect } from "react";
import en from "./../Language/en.json";
import ja from "./../Language/ja.json";
import bn from "./../Language/bn.json";

const useLanguageHook = (settings) => {
  const [lang, setLang] = useState(document.documentElement.lang || settings.lang);

  const languages = {
    en,
    ja,
    bn
  };

  const getLocalizedString = ({ key, count = 1, params, level = "" }) => {
    let translations = languages[lang] || languages["en"];
    let result = key;
    try {
      if (!translations) {
        return result;
      }

      if (level) {
        const levels = level.split(".");
        let currentTranslations = translations;
        for (const levelSegment of levels) {
          if (typeof currentTranslations === "object" && currentTranslations.hasOwnProperty(levelSegment)) {
            currentTranslations = currentTranslations[levelSegment];
          }
        }
        result = currentTranslations[key] ? currentTranslations[key] : result;
      } else {
        result = translations[key] ? translations[key] : key;
      }

      if (isPluralTranslation(result)) {
        let str = count <= 1 ? result.one : result.default;
        if (str) result = str;
      }

      if (params) {
        Object.keys(params).forEach((param) => {
          result = result.replace(`{${param}}`, params[param]);
        });
      }
    } catch (error) {
      //We don't need to handle this error
    }

    return result;
  };

  function isPluralTranslation(translation) {
    return typeof translation === "object" && "one" in translation && "default" in translation;
  }

  useEffect(() => {
    const handleLangChange = () => {
      setLang(document.documentElement.lang);
    };

    const observer = new MutationObserver((mutationsList, observer) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === "attributes" && mutation.attributeName === "lang") {
          handleLangChange();
        }
      });
    });

    observer.observe(document.documentElement, { attributes: true, attributeFilter: ["lang"] });

    return () => {
      observer.disconnect();
    };
  }, []);

  return { lang, getLocalizedString };
};

export default useLanguageHook;
