import { v4 as uuidv4, validate as uuidValidate } from "uuid";
import { API_URL } from "./constants";

const fetchData = async ({ restaurantID, apiEndPoint, method, bodyData, headers, withCredentials }) => {
  try {
    headers = {
      ...(headers || {})
    };
    const response = await fetch(API_URL + restaurantID + apiEndPoint, {
      method,
      headers: {
        "Content-Type": "application/json",
        ...(headers || {})
      },
      body: bodyData ? JSON.stringify(bodyData) : undefined,
      credentials: withCredentials ? "include" : "same-origin"
    });

    if (!response.ok) {
      const errorData = response.status != 204 ? await response.json() : {};
      return { statusCode: response.status, responseBody: null, error: errorData };
    }

    const responseBody = await response.json();

    return { statusCode: response.status, responseBody, error: null };
  } catch (error) {
    return { statusCode: null, responseBody: null, error: { error: "Request couldn't send" } };
  }
};

const clientID = () => {
  let clientID = localStorage.getItem("gm_clientID");
  if (!clientID || !uuidValidate(clientID)) {
    clientID = uuidv4();
    localStorage.setItem("gm_clientID", clientID);
  }
  return clientID;
};

export { fetchData, clientID };
