import React, { useEffect, useRef, useState } from "react";
import Style from "./BottomSlideModal.module.css";

export const BottomSlideModal = ({ children, show, handleClose, closingOffset = 150, readyToClose }) => {
  const [isDragging, setIsDragging] = useState(false);
  const dialogRef = useRef(null);
  const [initialHeight, setInitialHeight] = useState(null);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [classList, setClassList] = useState([Style.dialog]);
  const [modalShow, setModalShow] = useState(false);
  const timeOut = 700;

  useEffect(() => {
    if (show) {
      document.documentElement.classList.add("modal-open");
      setModalShow(true);
      setClassList([Style.dialog, Style.showingModal]);
      setCursorPosition(0);
      setTimeout(() => {
        if (dialogRef.current) {
          setInitialHeight(dialogRef.current.clientHeight);
        }
      }, timeOut);
    } else {
      setModalShow(false);
      setCursorPosition(0);
    }
  }, [show]);

  useEffect(() => {
    if (readyToClose) {
      closeModal();
    }
  }, [readyToClose]);

  const closeModal = () => {
    setClassList([Style.dialog, Style.hidingModal]);
    setTimeout(() => {
      handleClose();
      document.documentElement.classList.remove("modal-open");
    }, timeOut - 100);
  };

  const handleTouchStart = () => {
    setIsDragging(true);
  };

  const handleTouchMove = (event) => {
    if (!isDragging) return;
    const cursorPosition = window.innerHeight - initialHeight - event.touches[0].clientY;
    if (cursorPosition < 0) {
      setCursorPosition(cursorPosition);

      if (-1 * cursorPosition > closingOffset) {
        closeModal();
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        closeModal();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClose]);

  return (
    <>
      {modalShow && (
        <div className={Style.BottomSlideModal}>
          <div ref={dialogRef} className={classList.join(" ")} role="dialog" style={{ bottom: cursorPosition }}>
            {children}
            <button
              onTouchStart={handleTouchStart}
              onTouchEnd={() => {
                if (-1 * cursorPosition <= closingOffset) {
                  setCursorPosition(0);
                }
              }}
              onTouchMove={handleTouchMove}
              className={Style.closebtnTop}
            ></button>
          </div>
        </div>
      )}
    </>
  );
};

export const ModalBody = ({ children }) => {
  return <div className={Style.modalBody}>{children}</div>;
};
export const ModalFooter = ({ children }) => {
  return <div className={Style.footer}>{children}</div>;
};
