import { useState } from "react";
import { fetchData } from "../Utils/api";

const useFetchData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [code, setCode] = useState(null);

  const fetchDataWrapper = async ({ restaurantID, apiEndPoint, method, bodyData, headers, withCredentials }) => {
    setIsLoading(true);
    let config = { restaurantID, apiEndPoint, method, bodyData, headers, withCredentials };
    try {
      const { statusCode, responseBody, error } = await fetchData(config);
      if (responseBody && responseBody.data) {
        setData(responseBody.data);
      }
      setCode(code);
      setError(error);
    } catch (error) {
      setError({ error: "Request couldn't send" });
      return { statusCode: null, responseBody: null, error };
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, data, error, code, fetchData: fetchDataWrapper };
};

export default useFetchData;
