import React, { useEffect } from "react";
import Style from "./ModalMenuDetails.module.css";
import DynamicAspectRatioImage from "../DynamicAspectRatioImage/DynamicAspectRatioImage";
import { getName, getPrice } from "../../../../Utils/utils";
const MenuOption = ({ group, option, settings, handleRadio, handleCheckbox, selectedOptions, onClick }) => {
  return (
    <div
      onClick={() => {
        onClick(option.id);
      }}
      className={Style.option}
    >
      <div className={Style.imageContainer}>
        <DynamicAspectRatioImage offset={0} src={option.img} aspectRatio={1 / 1} />
      </div>
      <div className={Style.optionData}>
        <div className={Style.optionTitle}>
          <p>{getName(settings, option)}</p>
        </div>
        <p className={`${Style.optionPrice} ${settings.currency == "円" ? Style.yenPaddingOption : ""}`}>
          {getPrice(settings, option)}
          <span>{settings.currency}</span>
        </p>
      </div>
      <div className={Style.selector}>
        {group.type == "radio" ? (
          <>
            <input
              type="radio"
              name={group.id}
              className={Style.radio}
              checked={selectedOptions.includes(option.id) ? true : false}
              onChange={(e) => {
                handleRadio(option.id);
              }}
            />
          </>
        ) : (
          <>
            <input
              type="checkbox"
              name={group.id}
              className={Style.checkbox}
              checked={selectedOptions.includes(option.id) ? true : false}
              disabled={group.max_select && !selectedOptions.includes(option.id) && selectedOptions.length >= group.max_select ? true : false}
              onChange={(e) => {
                handleCheckbox(option.id);
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default MenuOption;
