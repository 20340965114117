import { AVAILABLE_LANG } from "./constants";

export const getName = (settings, data) => {
  if (!data) {
    return "";
  }
  if (settings.local) {
    if (data.name_local && data.name_local !== "") {
      return data.name_local;
    } else {
      return data.name || "";
    }
  } else {
    if (data.name && data.name !== "") {
      return data.name;
    } else {
      return data.name_local || "";
    }
  }
};

export const getDescription = (settings, data) => {
  if (!data) {
    return "";
  }
  if (settings.local) {
    if (data.description_local && data.description_local !== "") {
      return data.description_local;
    } else {
      return data.description || "";
    }
  } else {
    if (data.description && data.description !== "") {
      return data.description;
    } else {
      return data.description_local || "";
    }
  }
};

export const getPrice = (settings, data) => {
  let price = parseFloat(data.price || 0);
  if (settings.tax_included_price) {
    price = price + price * (settings.tax_percent / 100);
  }

  return price.toFixed(parseInt(settings.precision));
};

export const setEqualHeight = (className) => {
  const elements = document.querySelectorAll(className);
  let maxHeight = 0;

  elements.forEach((element) => {
    element.style.height = "auto";
    const height = element.offsetHeight;
    if (height > maxHeight) {
      maxHeight = height;
    }
  });

  elements.forEach((element) => {
    element.style.height = maxHeight + "px";
  });
};

export const getIngredientName = (settings, ingredient) => {
  if (settings.local) {
    if (settings.lang == "en") {
      return ingredient.name;
    }

    if (ingredient[`name_${settings.lang}`]) {
      return ingredient[`name_${settings.lang}`];
    }
  }
  return ingredient.name;
};

export const updateLang = (settings, setSettings) => {
  let savedLang = localStorage.getItem(`lang_${settings.identity}`);
  let settingLang = "en";

  if (settings && settings.lang && settings.local && AVAILABLE_LANG.includes(settings.lang)) {
    settingLang = settings.lang;
  }

  if (AVAILABLE_LANG.includes(savedLang)) {
    document.documentElement.lang = savedLang;
  } else {
    document.documentElement.lang = settingLang;
  }

  if (savedLang != settingLang) {
    setSettings((prevSettings) => ({ ...prevSettings, local: false }));
  }
};

export const formatDate = (timestamp) => {
  const milliseconds = timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
  const date = new Date(milliseconds);
  const now = new Date();
  const diffInSeconds = Math.floor((now - date) / 1000);

  if (diffInSeconds < 60) {
    return "";
  } else {
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) {
      return `${diffInMinutes}m`;
    } else {
      const diffInHours = Math.floor(diffInMinutes / 60);
      const remainingMinutes = diffInMinutes % 60;
      if (remainingMinutes === 0) {
        return `${diffInHours}h`;
      } else {
        return `${diffInHours}h ${remainingMinutes}m`;
      }
    }
  }
};

export const getNavigatorData = () => {
  const info = {};
  // Basic navigator properties
  info.userAgent = navigator.userAgent;
  info.language = navigator.language;
  info.platform = navigator.platform;
  // Screen properties
  info.screenWidth = window.screen.width;
  info.screenHeight = window.screen.height;
  info.screenColorDepth = window.screen.colorDepth;
  info.screenPixelDepth = window.screen.pixelDepth;
  // Window properties
  info.innerWidth = window.innerWidth;
  info.innerHeight = window.innerHeight;
  info.devicePixelRatio = window.devicePixelRatio;
  return info;
};
