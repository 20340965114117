import React, { useEffect, useState } from "react";
import useLanguageHook from "../../../../hooks/useLanguageHook";
import Style from "./Order.module.css";

const OrderPriceDetails = ({ tokenData, orderContent, settings, fromBilling = false }) => {
  const { getLocalizedString, lang } = useLanguageHook(settings);
  const [netPrice, setNetPrice] = useState(0);
  const [taxPrice, setTaxPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    let priceData = 0;
    orderContent.map((order) => {
      if (order.status == "canceled") {
        return;
      }
      priceData += (order.price ? order.price : 0) * order.quantity;
    });
    setNetPrice(priceData);

    if (settings.tax_percent) {
      setTaxPrice(priceData * (settings.tax_percent / 100));
    }
    setTotalPrice(priceData + priceData * (settings.tax_percent / 100));
  }, [orderContent]);
  return (
    <>
      {settings.add_tax && settings.tax_included_price ? (
        <div className={Style.priceDetails}>
          <div className={Style.item}>
            <div className={Style.key}>{getLocalizedString({ key: "Subtotal" })}</div>
            <div className={Style.value}>
              {settings.currency} {netPrice.toFixed(parseInt(settings.precision))}
            </div>
          </div>
          <div className={Style.item}>
            <div className={Style.key}>{getLocalizedString({ key: "Tax" })}</div>
            <div className={Style.value}>
              {settings.currency} {taxPrice.toFixed(parseInt(settings.precision))}
            </div>
          </div>
          <div className={Style.item}>
            <div className={`${Style.key} ${Style.sumup}`}>{getLocalizedString({ key: "Total Price" })}</div>
            <div className={`${Style.value} ${Style.sumup}`}>
              {settings.currency} {totalPrice.toFixed(parseInt(settings.precision))}
            </div>
          </div>
        </div>
      ) : (
        <>
          {fromBilling && (
            <div className={Style.priceDetails}>
              <div className={Style.item}>
                <div className={`${Style.key} ${Style.sumup}`}>{getLocalizedString({ key: "Total Price" })}</div>
                <div className={`${Style.value} ${Style.sumup}`}>
                  {settings.currency} {totalPrice.toFixed(parseInt(settings.precision))}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default OrderPriceDetails;
