import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Style from "./DynamicAspectRatioImage.module.css";
import { ClipLoader, MoonLoader } from "react-spinners";
import { HOST_URL } from "../../../../Utils/constants";
import LazyLoad from "react-lazyload";

const DynamicAspectRatioImage = ({ src, aspectRatio, alt, offset = 100 }) => {
  const [loading, setLoading] = useState(true);
  const [imageSrc, setImageSrc] = useState("");

  const handleImageLoaded = () => {
    setLoading(false);
  };

  useEffect(() => {
    if (src) {
      setImageSrc(HOST_URL + "uploads/" + src);
    }
  }, [src]);

  const handleImageError = (e) => {
    e.target.onerror = null;
    e.target.src = "/no_image.webp";
  };

  const paddingTopPercent = (1 / aspectRatio) * 100;

  return (
    <div style={{ position: "relative", width: "100%", paddingTop: `${paddingTopPercent}%` }}>
      {loading && (
        <div className={Style.loading}>
          <ClipLoader color="#FF6600" loading size={42} speedMultiplier={0.8} />
        </div>
      )}

      {offset > 0 ? (
        <LazyLoad offset={offset} once>
          <img src={imageSrc} alt={alt || "Gorilla Manager Item"} className={Style.img} onLoad={handleImageLoaded} onError={handleImageError} />
        </LazyLoad>
      ) : (
        <img src={imageSrc} alt={alt || "Gorilla Manager Item"} className={Style.img} onLoad={handleImageLoaded} onError={handleImageError} />
      )}
    </div>
  );
};

DynamicAspectRatioImage.propTypes = {
  src: PropTypes.string, // Change the prop type to string
  aspectRatio: PropTypes.number.isRequired,
  alt: PropTypes.string, // Make alt text optional
  offset: PropTypes.number // Make alt text optional
};

export default DynamicAspectRatioImage;
