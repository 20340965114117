import React, { useEffect, useRef, useState } from "react";
import Style from "./Sidebar.module.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { getName } from "../../../../Utils/utils";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";
const Sidebar = ({ settings, categories, selectedCategory, setSelectedCategory, isCategoryLoading }) => {
  const categoryButtonRefs = useRef([]);
  const handlePrev = () => {
    setSelectedCategory((prevCategory) => {
      const index = categories.findIndex((category) => category.id === selectedCategory.id);
      const newIndex = (index - 1 + categories.length) % categories.length; // Calculate the new index in a circular manner
      return categories[newIndex];
    });
  };

  const handleNext = () => {
    setSelectedCategory((prevCategory) => {
      const index = categories.findIndex((category) => category.id === selectedCategory.id);
      const newIndex = (index + 1) % categories.length; // Calculate the new index in a circular manner
      return categories[newIndex];
    });
  };

  useEffect(() => {
    if (selectedCategory) {
      if (categoryButtonRefs.current[selectedCategory.id]) {
        const selectedElement = categoryButtonRefs.current[selectedCategory.id].current;
        if (selectedElement) {
          setTimeout(() => {
            selectedElement.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "center"
            });
          }, 200); // for smooth scrolling
        }
      }
    }
  }, [selectedCategory]);

  useEffect(() => {}, [settings]);

  return (
    <div className={Style.sidebar}>
      <img src={"/logo.png"} className={Style.logo} />
      {!isCategoryLoading ? (
        <>
          <div className={Style.categories}>
            {categories && (
              <>
                {categories.map((cat, i) => {
                  categoryButtonRefs.current[cat.id] = React.createRef();
                  return (
                    <div
                      ref={categoryButtonRefs.current[cat.id]}
                      onClick={() => {
                        setSelectedCategory(cat);
                      }}
                      className={`${Style.btn} ${selectedCategory && cat.id == selectedCategory.id ? Style.active : ""}`}
                      key={i}
                    >
                      <p>{getName(settings, cat)}</p>
                    </div>
                  );
                })}
              </>
            )}
          </div>
          <div className={Style.navigator}>
            <button onClick={handlePrev}>
              <ArrowForwardIosIcon className={Style.iconTOP} />
            </button>
            <button onClick={handleNext}>
              <ArrowForwardIosIcon className={Style.iconBottom} />
            </button>
          </div>
        </>
      ) : (
        <div className={Style.placeholder}>
          <ReactPlaceholder color="#FFFFFF30" showLoadingAnimation={true} type="rect" ready={false} className={Style.placeholderItem}></ReactPlaceholder>
          <ReactPlaceholder color="#FFFFFF10" showLoadingAnimation={true} type="rect" ready={false} className={Style.placeholderItem}></ReactPlaceholder>
          <ReactPlaceholder color="#FFFFFF30" showLoadingAnimation={true} type="rect" ready={false} className={Style.placeholderItem}></ReactPlaceholder>
          <ReactPlaceholder color="#FFFFFF10" showLoadingAnimation={true} type="rect" ready={false} className={Style.placeholderItem}></ReactPlaceholder>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
