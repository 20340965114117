import React, { useEffect, useState } from "react";
import Style from "./ModalMenuDetails.module.css";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { getName, getNavigatorData, getPrice } from "../../../../Utils/utils";
import useLanguageHook from "../../../../hooks/useLanguageHook";
import { ClipLoader, PropagateLoader, PulseLoader } from "react-spinners";
import useFetchData from "../../../../hooks/useFetchData";
import { clientID } from "../../../../Utils/api";
import Popup from "../Popup/Popup";
const MenuDetailsFooter = ({ isLoading, orderPlace, tokenData, menu, orderData, setOrderData, settings }) => {
  const [quantity, setQuantity] = useState(1);
  const { getLocalizedString, lang } = useLanguageHook(settings);
  const [price, setPrice] = useState(menu.price);

  useEffect(() => {
    setOrderData((prevOrderData) => ({
      ...prevOrderData,
      menu_id: menu.id,
      menu_quantity: quantity,
      meta_data: getNavigatorData()
    }));
  }, [quantity]);

  useEffect(() => {
    if (quantity != 0) {
      let orderPrice = menu.price * quantity;
      const allGroupOptions = menu.menu_groups ? menu.menu_groups.flatMap((group) => group.group_options) : [];
      if (orderData.option) {
        orderData.option.map((item) => {
          const menuOption = allGroupOptions.find((option) => option.id === item.option_id);
          if (menuOption && menuOption.price) {
            orderPrice += (menuOption.price ? menuOption.price : 0) * item.option_quantity;
          }
        });
      }
      setPrice(orderPrice < 0 ? 0 : orderPrice);
    } else {
      setPrice(0);
    }
  }, [orderData]);

  const decrease = () => {
    if (quantity > 0) {
      setQuantity(quantity - 1);
    } else {
      setQuantity(0);
    }
  };

  const increase = () => {
    setQuantity(quantity + 1);
  };

  const sendOrder = () => {
    orderPlace({
      restaurantID: settings.identity,
      apiEndPoint: "/order",
      method: "POST",
      bodyData: orderData,
      headers: {
        "x-client-id": clientID(),
        "x-table": tokenData.token
      }
    });
  };

  return (
    <>
      <div className={Style.footer}>
        <div className={Style.counter}>
          <button onClick={decrease} className={Style.plusminus}>
            <RemoveIcon />
          </button>
          <div className={Style.count}>{quantity}</div>
          <button onClick={increase} className={Style.plusminus}>
            <AddIcon />
          </button>
        </div>
        <button onClick={sendOrder} disabled={quantity == 0 ? true : false} className={Style.orderBtn}>
          {isLoading ? (
            <div className={Style.orderPlacing}>
              <PulseLoader color="#fff" /> {getLocalizedString({ key: "Order Placing" })}
            </div>
          ) : (
            <>
              {getLocalizedString({ key: "Send to Kitchen" })}{" "}
              <span>
                {settings.currency}
                {getPrice(settings, { price })}
              </span>
            </>
          )}
        </button>
      </div>
    </>
  );
};

export default MenuDetailsFooter;
