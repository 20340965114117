import React, { useEffect, useState } from "react";
import Style from "./Order.module.css";
import DynamicAspectRatioImage from "../DynamicAspectRatioImage/DynamicAspectRatioImage";
import { formatDate, getName, getPrice } from "../../../../Utils/utils";
import useLanguageHook from "../../../../hooks/useLanguageHook";
import { ClipLoader, MoonLoader } from "react-spinners";
import DeleteIcon from "@mui/icons-material/Delete";
import useFetchData from "../../../../hooks/useFetchData";
import { clientID } from "../../../../Utils/api";
import Popup from "../Popup/Popup";
const OrderItem = ({ tokenData, order, settings, fromBilling }) => {
  const { getLocalizedString, lang } = useLanguageHook(settings);
  const [price, setPrice] = useState(0);
  const [isDeleteing, setIsdeleting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [orderOptionName, setOrderOptionName] = useState([]);
  const { isLoading: isOrderDeleteLoading, data: orderDeleteData, error: orderDeleteError, code: orderDeleteCode, fetchData: orderDelete } = useFetchData();

  useEffect(() => {
    let price = 0;
    let items = [];
    price += (order.price ? order.price : 0) * order.quantity;
    if (order.option && Array.isArray(order.option) && order.option.length > 0) {
      order.option.map((item, i) => {
        items.push(getName(settings, item));
      });
      setOrderOptionName(items);
    }
    setPrice(price);
    setIsdeleting(false);
  }, [order]);

  useEffect(() => {
    if (orderDeleteError) {
      if (typeof orderDeleteError.error == "string") {
        setErrorMessage(orderDeleteError.error);
      } else {
        setErrorMessage(JSON.stringify(orderDeleteError.error));
      }
    }
  }, [orderDeleteError]);

  const deleteOrder = () => {
    setIsdeleting(true);
    orderDelete({
      restaurantID: settings.identity,
      apiEndPoint: "/order",
      method: "DELETE",
      bodyData: {
        order_food_id: order.id
      },
      headers: {
        "x-client-id": clientID(),
        "x-table": tokenData.token
      }
    });
  };

  const handleErrorClose = () => {
    setErrorMessage(null);
    setIsdeleting(false);
  };

  const timeAgo = formatDate(order.created_at);

  return (
    <>
      <div className={`${Style.orderItem} ${Style[order.status + "_border"]}`}>
        <div className={Style.orderInfo}>
          <div className={Style.order_food_id}>#{order.id.substr(order.id.length - 4)}</div>
          <div className={Style.order_time}>{timeAgo == "" ? getLocalizedString({ key: "Just Now" }) : getLocalizedString({ key: "{time} ago", params: { time: timeAgo } })}</div>
        </div>

        <div className={Style.menuDetails}>
          <div className={Style.imageContainer}>
            <DynamicAspectRatioImage offset={0} src={order.img} aspectRatio={1 / 1} />
          </div>
          <div className={Style.quantity}>
            <p className={Style.quantity_title}>{getLocalizedString({ key: "Quantity" })}</p>
            <p className={Style.quantity_count}>{order.quantity}</p>
          </div>

          <div className={Style.menuInfo}>
            <p className={Style.menuTitle}>{getName(settings, order)}</p>
            <div className={Style.price_delete_btn}>
              <p className={Style.menuPrice}>
                {settings.currency} {getPrice(settings, { price })}
              </p>

              {order.status == "pending" && fromBilling == false && (
                <>{!isDeleteing ? <DeleteIcon onClick={deleteOrder} className={Style.icon} /> : <ClipLoader size={16} color="#FF6600" />}</>
              )}
            </div>
          </div>
        </div>

        {order.option && Array.isArray(order.option) && order.option.length > 0 && (
          <div className={Style.orderOption}>
            <p className={Style.with}>{getLocalizedString({ key: "With" })}:</p>
            <div className={Style.options}>{orderOptionName.join(" | ")} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
          </div>
        )}

        <div className={`${Style.status} ${Style[order.status]} `}>
          <p>{getLocalizedString({ key: order.status })}</p>
        </div>
      </div>

      {/* <div className={Style.imageContainer}>
          <DynamicAspectRatioImage offset={0} src={order.img} aspectRatio={1 / 1} />
        </div>
        <div className={Style.details}>
          <p className={Style.menuTitle}>{getName(settings, order)}</p>
          <p className={Style.menuDetails}>
            <span>
              {getLocalizedString({ key: "Quantity" })}: {order.menu_quantity}
            </span>{" "}
            ({formatDate(order.created_at)})
          </p>

          <div className={Style.price_status}>
            <p className={`${Style.price} ${settings.currency == "円" ? Style.yenPadding : ""} ${order.status == "canceled" ? Style.line_through : ""}`}>
              {getPrice(settings, { price })}
              <span>{settings.currency}</span>
            </p>

            <div className={Style.statusContent}>
              {!isDeleteing && (
                <>
                  <div className={Style.statusContainer}>
                    <div className={`${Style.status} ${Style[order.status]} `}>{getLocalizedString({ key: order.status })}</div>
                    {order.status == "pending" && <DeleteIcon onClick={deleteOrder} className={Style.icon} />}
                  </div>
                </>
              )}

              {isDeleteing && <ClipLoader size={16} color="#FF6600" />}
            </div>
          </div>
        </div> */}

      {errorMessage && (
        <Popup
          type="error"
          title={getLocalizedString({ key: "Error" })}
          message={errorMessage}
          button={getLocalizedString({ key: "Okay" })}
          show={errorMessage ? true : false}
          handleClose={handleErrorClose}
        />
      )}
    </>
  );
};

export default OrderItem;
