// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MenuApp_app__pvWYY {
    width: 100%;
    height: 100%;

}

.MenuApp_top__nCjfV {
    width: 100%;
    height: 100%;
    display: flex
}

.MenuApp_sidebar__B75\\+k {
    width: var(--sidebar-width);
    position: fixed;
    top: 0;
    left: 0;
    height: calc(100% - var(--bottom-menu-height));
    background-color: var(--color-body);
}

.MenuApp_menulist__y3Z\\+3 {
    margin-left: var(--sidebar-width);
    width: calc(100% - var(--sidebar-width));
    background-color: var(--color-body);
    height: auto;
    min-height: 100vh;
}



.MenuApp_footer__m67kC {
    width: 100%;
    height: var(--bottom-menu-height);
    box-shadow: 0 -4px 14px 2px #0000004d;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: var(--color-body);
    z-index: 3;
}

.MenuApp_loader__2Lve7 {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/Restaurant/MenuApp/MenuApp.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;;AAEhB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ;AACJ;;AAEA;IACI,2BAA2B;IAC3B,eAAe;IACf,MAAM;IACN,OAAO;IACP,8CAA8C;IAC9C,mCAAmC;AACvC;;AAEA;IACI,iCAAiC;IACjC,wCAAwC;IACxC,mCAAmC;IACnC,YAAY;IACZ,iBAAiB;AACrB;;;;AAIA;IACI,WAAW;IACX,iCAAiC;IACjC,qCAAqC;IACrC,4BAA4B;IAC5B,6BAA6B;IAC7B,eAAe;IACf,SAAS;IACT,OAAO;IACP,mCAAmC;IACnC,UAAU;AACd;;AAEA;IACI,WAAW;IACX,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":[".app {\n    width: 100%;\n    height: 100%;\n\n}\n\n.top {\n    width: 100%;\n    height: 100%;\n    display: flex\n}\n\n.sidebar {\n    width: var(--sidebar-width);\n    position: fixed;\n    top: 0;\n    left: 0;\n    height: calc(100% - var(--bottom-menu-height));\n    background-color: var(--color-body);\n}\n\n.menulist {\n    margin-left: var(--sidebar-width);\n    width: calc(100% - var(--sidebar-width));\n    background-color: var(--color-body);\n    height: auto;\n    min-height: 100vh;\n}\n\n\n\n.footer {\n    width: 100%;\n    height: var(--bottom-menu-height);\n    box-shadow: 0 -4px 14px 2px #0000004d;\n    border-top-left-radius: 16px;\n    border-top-right-radius: 16px;\n    position: fixed;\n    bottom: 0;\n    left: 0;\n    background-color: var(--color-body);\n    z-index: 3;\n}\n\n.loader {\n    width: 100%;\n    height: 100vh;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app": `MenuApp_app__pvWYY`,
	"top": `MenuApp_top__nCjfV`,
	"sidebar": `MenuApp_sidebar__B75+k`,
	"menulist": `MenuApp_menulist__y3Z+3`,
	"footer": `MenuApp_footer__m67kC`,
	"loader": `MenuApp_loader__2Lve7`
};
export default ___CSS_LOADER_EXPORT___;
