import React, { useEffect, useRef, useState } from "react";
import Sidebar from "./Components/Sidebar/Sidebar";
import MenuList from "./Components/MenuList/MenuList";
import Style from "./MenuApp.module.css";
import Footer from "./Components/Footer/Footer";
import { clientID, fetchData } from "./../../Utils/api";

import { BottomSlideModal, ModalFooter, ModalBody } from "./Components/BottomSlideModal/BottomSlideModal";
import { GridLoader } from "react-spinners";
import { useNavigate, useParams } from "react-router-dom";
import useFetchData from "../../hooks/useFetchData";
import { HOST_URL } from "../../Utils/constants";
import { updateLang } from "../../Utils/utils";
import { useCollectionSnap } from "../../hooks/useGetDocSnap";
import BillingList from "./Components/BillingList/BillingList";

const MenuApp = () => {
  const hostnameParts = window.location.hostname.split(".");
  const [error, setError] = useState(null);
  const pararms = useParams();
  const navigate = useNavigate();
  const [tokenData, setTokenData] = useState(null);
  const { isLoading: isLoadingRestaurantInfo, data: restaurantData, error: restaurantError, code: restaurantCode, fetchData: restaurantFetchData } = useFetchData();
  const { isLoading: isCategoryLoading, data: categoryData, error: categoryError, code: categoryCode, fetchData: categoryFetch } = useFetchData();
  const [categories, setCategories] = useState([]);
  const [orderContent, setOrderContent] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [tableData, setTableData] = useState({});
  const [order, setOrder] = useState({});
  const [restaurentID, setrestaurentID] = useState(process.env.NODE_ENV === "development" ? hostnameParts[1] : hostnameParts[0]);

  const { documents: fetchedTable, isPending: tablePending, getMultipleDocs: getTableDocs } = useCollectionSnap();
  const { documents: fetchedOrderContent, isPending: orderContentPending, getMultipleDocs: getOrderContentDocs } = useCollectionSnap();
  const { documents: fetchedOrder, isPending: orderPending, getMultipleDocs: getOrder } = useCollectionSnap();

  const [settings, setSettings] = useState({
    id: "",
    name: "",
    identity: "",
    phone: "",
    logo_url: "",
    description: "",
    address_line_1: "",
    address_line_2: "",
    tax_reg_number: "",
    business_reg: "",
    country: "",
    local: true,
    currency: "$",
    lang: "ja",
    precision: 1,
    add_tax: false,
    tax_included_price: false,
    tax_percent: "0.00"
  });

  useEffect(() => {
    console.log("ok");
    if (restaurentID) {
      verifyCode();
    }
  }, [pararms, restaurentID]);

  const verifyCode = async () => {
    const { statusCode, responseBody, error } = await fetchData({
      restaurantID: restaurentID,
      apiEndPoint: "/verifyQR",
      method: "POST",
      bodyData: {
        QRCode: pararms.tableCODE
      }
    });

    if (responseBody) {
      let { data } = responseBody;
      if (data.token) {
        setTokenData(data);
      } else {
        setError("error");
      }
    } else {
      setError("error");
    }
  };

  useEffect(() => {
    if (error) {
      navigate("/");
    }
  }, [error]);

  useEffect(() => {
    if (tokenData) {
      loadData();
    }
  }, [tokenData]);

  useEffect(() => {
    if (fetchedTable && fetchedTable.length > 0) {
      setTableData(fetchedTable[0]);
    }
  }, [fetchedTable]);

  useEffect(() => {
    if (tableData && tableData.table_no) {
      orderLoad();
    }
  }, [tableData]);

  useEffect(() => {
    if (fetchedOrderContent) {
      setOrderContent(fetchedOrderContent);
    }
  }, [fetchedOrderContent]);

  useEffect(() => {
    if (fetchedOrder && Array.isArray(fetchedOrder) && fetchedOrder.length > 0) {
      setOrder(fetchedOrder[0]);

      if (fetchedOrder[0].status && fetchedOrder[0].status == "complete") {
        navigate("/");
      }
    }
  }, [fetchedOrder]);

  const loadData = () => {
    loadFirebaseTable();
    categoryLoad();
    restaurantLoad();
  };

  const orderLoad = () => {
    getOrderContentDocs({
      _collection: restaurentID + "_order_menus",
      _query: [["table", "==", tableData.table_no]]
    });
    getOrder({
      _collection: restaurentID + "_orders",
      _query: [
        ["table_no", "==", tableData.table_no],
        ["token", "==", tokenData.token]
      ]
    });
  };

  const categoryLoad = () => {
    categoryFetch({
      restaurantID: restaurentID,
      apiEndPoint: "/categories",
      method: "GET",
      headers: {
        "x-client-id": clientID(),
        "x-table": tokenData.token
      }
    });
  };

  const restaurantLoad = () => {
    restaurantFetchData({
      restaurantID: restaurentID,
      apiEndPoint: "/",
      method: "GET",
      headers: {
        "x-client-id": clientID(),
        "x-table": tokenData.token
      }
    });
  };

  const loadFirebaseTable = () => {
    getTableDocs({
      _collection: restaurentID + "_tables",
      _query: [["code", "==", pararms.tableCODE]]
    });
  };

  useEffect(() => {
    if (categoryData && Array.isArray(categoryData) && categoryData.length > 0) {
      const sortedCategories = categoryData.sort((a, b) => a.position - b.position);
      setCategories(sortedCategories);
      setSelectedCategory(sortedCategories[0]);
    }
  }, [categoryData]);

  useEffect(() => {
    if (restaurantData) {
      setSettings({ ...settings, ...restaurantData });
      updateLang(restaurantData, setSettings);
    }
  }, [restaurantData]);

  return (
    <>
      <div className={Style.app}>
        {order && (order.status == "billing" || order.status == "complete") ? (
          <BillingList orderContent={orderContent} settings={settings} tokenData={tokenData} />
        ) : (
          <>
            <div className={Style.top}>
              <div className={Style.sidebar}>
                <Sidebar
                  settings={settings}
                  categories={categories}
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                  isCategoryLoading={isCategoryLoading}
                />
              </div>
              <div className={Style.menulist}>
                <MenuList settings={settings} tokenData={tokenData} selectedCategory={selectedCategory} />
              </div>
            </div>
            <div className={Style.footer}>
              <Footer tableData={tableData} tokenData={tokenData} orderContent={orderContent} settings={settings} setSettings={setSettings} />
            </div>
          </>
        )}
      </div>

      {/* <div className={Style.loader}>
        <GridLoader color="#FF6600" />
      </div> */}
    </>
  );
};

export default MenuApp;
