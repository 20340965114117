import { initializeApp } from "firebase/app";
import { initializeFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBcHz_xaC7EdDYVFaUmOtRzP0FzmeNBtMo",
  authDomain: "gorillaapp-d7b7a.firebaseapp.com",
  projectId: "gorillaapp-d7b7a",
  storageBucket: "gorillaapp-d7b7a.appspot.com",
  messagingSenderId: "1046187322447",
  appId: "1:1046187322447:web:c06faa29d2477311c60e3a"
};
const app = initializeApp(firebaseConfig);
const db = initializeFirestore(app, { useFetchStreams: false });
export { db };
