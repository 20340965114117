import React, { useEffect, useState } from "react";
import Style from "./ModalMenuDetails.module.css";
import DynamicAspectRatioImage from "../DynamicAspectRatioImage/DynamicAspectRatioImage";
import { getDescription, getIngredientName, getName, getNavigatorData, getPrice } from "../../../../Utils/utils";
import MenuGroup from "./MenuGroup";
import useLanguageHook from "../../../../hooks/useLanguageHook";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { PulseLoader } from "react-spinners";
import useFetchData from "../../../../hooks/useFetchData";
import { clientID } from "../../../../Utils/api";

const ModalMenuDetails = ({ tokenData, menu, settings, closeModal, setOrderPlaced }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [selectedGroupOptions, setSelectedGroupOptions] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const { getLocalizedString, lang } = useLanguageHook(settings);
  const [orderData, setOrderData] = useState({});
  const [price, setPrice] = useState(menu.price);
  const { isLoading, data: orderPlaceData, error, code, fetchData: orderPlace } = useFetchData();

  useEffect(() => {
    const initialTheme = document.body.getAttribute("theme");
    setIsDarkMode(initialTheme === "dark");
  }, []);

  useEffect(() => {
    const optionsArray = Object.values(selectedGroupOptions).flat();
    const uniqueOptionIds = new Set();
    const uniqueOptions = [];

    optionsArray.forEach((option) => {
      if (!uniqueOptionIds.has(option.option_id)) {
        uniqueOptionIds.add(option.option_id);
        uniqueOptions.push(option);
      }
    });

    // Update the orderData state with the new unique option data
    setOrderData((prevOrderData) => ({
      ...prevOrderData,
      option: uniqueOptions
    }));
  }, [selectedGroupOptions]);

  useEffect(() => {
    setOrderData((prevOrderData) => ({
      ...prevOrderData,
      menu_id: menu.id,
      menu_quantity: quantity,
      meta_data: getNavigatorData()
    }));
  }, [quantity]);

  useEffect(() => {
    if (quantity != 0) {
      let orderPrice = menu.price * quantity;
      const allGroupOptions = menu.menu_groups ? menu.menu_groups.flatMap((group) => group.group_options) : [];
      if (orderData.option) {
        orderData.option.map((item) => {
          const menuOption = allGroupOptions.find((option) => option.id === item.option_id);
          if (menuOption && menuOption.price) {
            orderPrice += (menuOption.price ? menuOption.price : 0) * item.option_quantity;
          }
        });
      }
      setPrice(orderPrice < 0 ? 0 : orderPrice);
    } else {
      setPrice(0);
    }
  }, [orderData]);

  const decrease = () => {
    if (quantity > 0) {
      setQuantity(quantity - 1);
    } else {
      setQuantity(0);
    }
  };

  const increase = () => {
    setQuantity(quantity + 1);
  };

  const sendOrder = () => {
    orderPlace({
      restaurantID: settings.identity,
      apiEndPoint: "/order",
      method: "POST",
      bodyData: orderData,
      headers: {
        "x-client-id": clientID(),
        "x-table": tokenData.token
      }
    });
  };

  useEffect(() => {
    if (orderPlaceData) {
      closeModal();
      setOrderPlaced(true);
    }
  }, [orderPlaceData]);

  return (
    <>
      <div className={Style.menu}>
        <div className={Style.menuInfoContainer}>
          <DynamicAspectRatioImage offset={0} src={menu.img} aspectRatio={1 / 1} />

          <div className={Style.data}>
            <p className={Style.name}>{getName(settings, menu)}</p>
            <div className={Style.priceData}>
              <p className={`${Style.price} ${settings.currency == "円" ? Style.yenPadding : ""}`}>
                {getPrice(settings, menu)}
                <span>{settings.currency}</span>
              </p>
            </div>

            <div className={Style.spice}>
              {[1, 2, 3, 4].map((count, i) => {
                if (count <= menu.spice_level) {
                  return <img src="/spiceF.png" key={i} className={Style.spcieImg} />;
                } else {
                  return <img src={isDarkMode ? "/spiceEMD.png" : "/spiceEML.png"} key={i} className={Style.spcieImg} />;
                }
              })}
            </div>
          </div>
        </div>

        <p className={Style.description}>{getDescription(settings, menu)}</p>

        {menu.ingredients && Array.isArray(menu.ingredients) && menu.ingredients.length > 0 && (
          <>
            <p className={Style.sectionTitle}>Ingredients</p>
            <div className={Style.ingredients}>
              {menu.ingredients.map((ingredient, i) => {
                return <span key={i}>{getIngredientName(settings, ingredient)}</span>;
              })}
            </div>
          </>
        )}

        {menu.menu_groups && Array.isArray(menu.menu_groups) && menu.menu_groups.length > 0 && (
          <>
            {menu.menu_groups.map((item, i) => {
              return <MenuGroup key={i} group={item} selectedGroupOptions={selectedGroupOptions} setSelectedGroupOptions={setSelectedGroupOptions} settings={settings} />;
            })}
          </>
        )}
      </div>

      <div className={Style.footer}>
        <div className={Style.counter}>
          <button onClick={decrease} className={Style.plusminus}>
            <RemoveIcon />
          </button>
          <div className={Style.count}>{quantity}</div>
          <button onClick={increase} className={Style.plusminus}>
            <AddIcon />
          </button>
        </div>
        <button onClick={sendOrder} disabled={quantity == 0 ? true : false} className={Style.orderBtn}>
          {isLoading ? (
            <div className={Style.orderPlacing}>
              <PulseLoader color="#fff" /> {getLocalizedString({ key: "Order Placing" })}
            </div>
          ) : (
            <>
              {getLocalizedString({ key: "Send to Kitchen" })}{" "}
              <span>
                {settings.currency}
                {getPrice(settings, { price })}
              </span>
            </>
          )}
        </button>
      </div>
    </>
  );
};

export default ModalMenuDetails;
